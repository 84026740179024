// Vue の props や data でやり取りするためシリアライズ可能な値に変換する

const tourDocToData = (
  tourDoc: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
) => {
  // GeoPointクラスを変換する
  const spots: Peeeps.Data.TourSpot[] = [];
  if (tourDoc.get("spots")) {
    tourDoc.get("spots").forEach((spot: Peeeps.Data.TourSpot) => {
      spots.push({
        place_id: spot.place_id,
        name_en: spot.name_en,
        name_ja: spot.name_ja,
        "name_zh-Hans": spot["name_zh-Hans"],
        method: spot.method,
        location_en: spot.location_en,
        location_ja: spot.location_ja,
        "location_zh-Hans": spot["location_zh-Hans"],
        desc_en: spot.desc_en,
        desc_ja: spot.desc_ja,
        "desc_zh-Hans": spot["desc_zh-Hans"],
        geo: { latitude: spot.geo!.latitude, longitude: spot.geo!.longitude },
      });
    });
  }
  const tour: Peeeps.Data.Tour = {
    id: tourDoc.id,
    image: tourDoc.get("image") || "",
    user: tourDoc.get("user"),
    lang: tourDoc.get("lang"),
    title: tourDoc.get("title"),
    title_en: tourDoc.get("title_en"),
    title_ja: tourDoc.get("title_ja"),
    "title_zh-Hans": tourDoc.get("title_zh-Hans"),
    description: tourDoc.get("description"),
    description_en: tourDoc.get("description_en"),
    description_ja: tourDoc.get("description_ja"),
    "description_zh-Hans": tourDoc.get("description_zh-Hans"),
    category: tourDoc.get("category"),
    credit: tourDoc.get("credit") || "",
    estimated_time: tourDoc.get("estimated_time"),
    review: tourDoc.get("review") || 0,
    num_reviews: tourDoc.get("num_reviews") || 0,
    // tags は tour_tags の document ID の配列にする
    tags: (tourDoc.get("tags") || []).map(
      (tagDocRef: firebase.default.firestore.DocumentReference) => tagDocRef.id
    ),
    tags_en: tourDoc.get("tags_en") || [],
    tags_ja: tourDoc.get("tags_ja") || [],
    "tags_zh-Hans": tourDoc.get("tags_zh-Hans") || [],
    spots,
    draft: tourDoc.get("draft"),
    suspended: tourDoc.get("suspended"),
    pref_codes: tourDoc.get("pref_codes") || [],
    num_entried: tourDoc.get("num_entried") || 0,
    num_completed: tourDoc.get("num_completed") || 0,
    num_favorites: tourDoc.get("num_favorites") || 0,
    start: tourDoc.get("start"),
    end: tourDoc.get("end"),
    created_at: tourDoc.get("created_at").toDate(),
    updated_at: tourDoc.get("updated_at").toDate(),
  };
  return tour;
};

const tourHitToData = (hit: any) => {
  // GeoPointクラスを変換する
  const spots: Peeeps.Data.TourSpot[] = [];
  hit.spots.forEach((spot: any) => {
    spots.push({
      place_id: spot.place_id,
      name_en: spot.name_en,
      name_ja: spot.name_ja,
      "name_zh-Hans": spot["name_zh-Hans"],
    });
  });
  const tour: Peeeps.Data.Tour = {
    id: hit.objectID,
    image: hit.image || "",
    user: hit.user,
    lang: hit.lang,
    title: hit.title,
    title_en: hit.title_en,
    title_ja: hit.title_ja,
    "title_zh-Hans": hit["title_zh-Hans"],
    description: hit.description,
    description_en: hit.description_en,
    description_ja: hit.description_ja,
    "description_zh-Hans": hit["description_zh-Hans"],
    category: hit.category,
    credit: hit.credit || "",
    estimated_time: hit.estimated_time,
    review: hit.review || 0,
    num_reviews: hit.num_reviews || 0,
    tags: hit.tags || [],
    tags_en: hit.tags_en || [],
    tags_ja: hit.tags_ja || [],
    "tags_zh-Hans": hit["tags_zh-Hans"] || [],
    spots,
    draft: hit.draft,
    suspended: hit.suspended,
    pref_codes: hit.pref_codes || [],
    num_entried: hit.num_entried || 0,
    num_completed: hit.num_completed || 0,
    num_favorites: hit.num_favorites || 0,
    start: hit.start,
    end: hit.end,
    created_at: new Date(hit.created_at * 1000),
    updated_at: new Date(hit.updated_at * 1000),
  };
  return tour;
};

export { tourDocToData, tourHitToData };
