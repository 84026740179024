
import Vue from "vue";

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    fromReplica: {
      type: Boolean,
      default: false,
    },
    tours: {
      type: Array,
      default: () => [],
    },
    useFetcher: {
      type: Boolean,
      default: false,
    },
    tourIdList: {
      type: Array,
      default: () => [],
    },
  },
});
