import { render, staticRenderFns } from "./TourFetcher.vue?vue&type=template&id=a6cc12d0&"
import script from "./TourFetcher.vue?vue&type=script&lang=ts&"
export * from "./TourFetcher.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TourCardSkelton: require('/home/runner/work/peeeps-spot/peeeps-spot/src/components/molecules/TourCardSkelton.vue').default})
