
import Vue from "vue";
import { tourDocToData } from "@/convertData";

export default Vue.extend({
  props: {
    tourId: {
      type: String,
      required: true,
    },
  },
  data() {
    const tour: Peeeps.Data.Tour = {};
    return { tour, loading: true };
  },
  async mounted() {
    const tourDoc = await this.$fire.firestore
      .doc(`tours/${this.tourId}`)
      .get()
      .catch((err) => {
        this.$sentry.captureException(err);
      });
    if (tourDoc && tourDoc.exists) {
      this.tour = tourDocToData(tourDoc);
    }
    this.loading = false;
  },
});
